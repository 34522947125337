import React, { useState, useEffect, useRef } from "react";
import InfiniteScroll from "react-infinite-scroller";
import axios from "axios";
import ListGroup from "react-bootstrap/ListGroup";
import { Card, Row, Col, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { AccountBalanceOutlined } from "@mui/icons-material";
import DropdownButton from "react-bootstrap/DropdownButton";
import PreLoader from "../../../PreLoader";
import DeleteIcon from "@mui/icons-material/Delete";
// import Button from '@mui/material/Button';
import StarIcon from "@mui/icons-material/Star";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Typography from "@mui/material/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { SvgIcon } from "@mui/material";
import { Home, Search } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BadgeAvatar from "../../../BadgeAvatar";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PVLNEventDetails from "./PVLNEventDetails";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import { InputTags } from "react-bootstrap-tagsinput";
import "react-bootstrap-tagsinput/dist/index.css";
// import Upload from "../../../Cropper/Upload";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Dropdown } from "react-bootstrap";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import Collapse from "react-bootstrap/Collapse";
import { ECOSYSTEM_OPTIONS } from "../../../PavilionConstants";
import Select from "react-select";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Popup from "../../../Cropper/Popup";
import { styled } from "@mui/material/styles";
import eventBanner from "../../../../assets/img/default-event-banner.jpg";
import http, { baseUrl } from "../../../../http";
import CRUDDropdown from "../../../CRUDDropdown";
import Image from "react-bootstrap/Image";

import { CKEditor, ImageInsert } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CropperPopup from "../../../Cropper/Popup";
import pubThumbnail from "../../../../assets/img/default-pubn-thumbnail.jpg";

import { PLSIZE, formatDate } from "../../../PavilionConstants";
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";
import OpenIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/VisibilityOff";

const CropperInput = styled("input")({
  display: "none",
});

// import PieChart from "../../../Charts/PieChart"

function PVLNEvents(props, { handleClick }) {
  const theme = localStorage.getItem("data-bs-theme");

  const [validated, setValidated] = useState(false);

  const [agree, setAgree] = useState(false);

  const [progress, setProgress] = useState(false);
  const [permissions, setPermissions] = useState({});
  const scrollContainerRef = useRef(null);

  const [pageTitle, setPageTitle] = useState("Events");

  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endTime, setEndTime] = useState("");

  const [hasMore, setHasMore] = useState(true);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [loadMsg, setLoadMsg] = useState(<PreLoader size={PLSIZE} />);
  const token = localStorage.getItem("token");
  const [editorData, setEditorData] = useState("");
  const [imageFile, setFile] = useState(
    localStorage.getItem("bucketName") + "/images/noimage.webp"
  );
  const [imagePress, setImagePress] = useState(eventBanner);
  const [imagePress2, setImagePress2] = useState("");

  const [events, setEvents] = useState([]);
  const [eventCategories, setEventCategories] = useState([]);
  const [image2] = useState(eventBanner);
  const [editEvent, setEditEvent] = useState(false);
  const [eventId, setEventId] = useState();

  const onChange = (cropper: CropperRef) => {
    console.log(cropper.getCoordinates(), cropper.getCanvas());
  };
  const [eventToEdit, setEventToEdit] = useState(null);

  // let timeStamp = Date.parse("14 Oct 2022");
  //alert(new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(timeStamp));
  const [showEvents, setShowEvents] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [intesetedmsg, setIntesetedmsg] = useState("interested");
  const [unintesetedmsg, setUnintesetedmsg] = useState("uninterested");
  const [intesetedmsgcolor, setIntesetedmsgcolor] = useState("primary");
  const [unintesetedmsgcolor, setUnintesetedmsgcolor] = useState("secondary");

  const [show, setShow] = useState(false);
  const handleParentClose = () => {
    setProgress(false)
    setEditEvent(false);
    setShow(false)
  };
  const handleShow = () => {
    setProgress(false);
    setEditEvent(false);
    setImagePress(eventBanner);
    setIsFree("0");
    setIsOpen("1");
    setStartDate("");
    setStartTime("");
    setEndDate("");
    setEndTime("");
    setEMType('0')
    setFormData({
      name: "",
      pavilion_id: props.idx,
      timezone: "",
      category_id: "",
      description: "",
      location: "",
      organizer: "",
      capacity: "",
      frequency: "",
      zoom_link: "",
      start_date: "",
      start_time: "",
      end_date: "",
      end_time: "",
      guest_or_all: "",
      invite_all_members: "",
      event_type: 1,
      event_meeting_type: eMType,
      meeting_status: isFree,
      online_price: '0',
      offline_price: '0',
      status: 1,
      meeting_type: "",
      image: eventBanner,
      // is_featured: '0',
    });
    setShow(true);
  };
  // const [dsType, setDSType] = useState(false);
  // const handleDSTypeClick = (e) => {
  //   if (e.target.value === "0") {
  //     setDSType(true)
  //   } else {
  //     setDSType(false)
  //   }
  // }
  const [state, setState] = useState([]);
  const handleClose = () => {
    setOpen(false);
  };
  const [open, setOpen] = React.useState(false);
  const [image, setImage] = React.useState(eventBanner);

  const [btnText, setBtnText] = useState(["Current", "Know More"]);
  const [rowIndex, setRowIndex] = useState();
  const handleEventClick = (idx) => {
    //  if (idx === 0) {
    setShowEvents(1);
    setRowIndex(idx);
    //  }
  };

  const [isFree, setIsFree] = useState("0");
  const [isOpen, setIsOpen] = useState("1");

  const [isGuests, setIsGuests] = useState(false);

  const [eMType, setEMType] = useState("0");

  const handleEventMeetingTypeClick = (e) => {
    setEMType(e.target.value);
    if(e.target.value==='0'){
      setFormData({ ...formData, zoom_link: "" });
    }
    if(e.target.value==='1'){
      setFormData({ ...formData, location: "" });
    }
  };
  const handleChangeMS = (e) => {
    setIsFree(e.target.value);
    if (e.target.value === "0") {
      setFormData({ ...formData, online_price: "" });
    }
  };

  const handleChangeOC = (e) => {
    setIsOpen(e.target.value);
  };

  const handleImageError = (e) => {
    e.target.src = localStorage.getItem("bucketName") + "/images/noimage.webp";
  };
  //input file dialogue
  const onFileChange = (e) => {
    e.preventDefault();
    const dataValue = e.currentTarget.getAttribute("data-value");
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files.length === 0) {
      return alert("Please select a file.");
    }
    const reader = new FileReader();
    reader.onload = () => {
      // getUploadedFile(reader.result);
      if (dataValue === "EVENTCROP") {
        setImagePress2(reader.result);
        setImagePress(reader.result);
        setFormData({ ...formData, image: files[0] });
        setOpen(true);
      }
    };

    //setFormData({ ...formData, image: e.target.files[0]});

    reader.readAsDataURL(files[0]);
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setFormData({ ...formData, description: data });
  };
  //input file dialog
  // const [show, setShow] = useState(false);
  function handleDropdown(event) {
    event.stopPropagation();
    // console.log('handleChildClick');
  }

  function handleDelete(id) {
    // console.log('handle Delete');
    Swal.fire({
      title: "Are you sure to delete this event?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#e30613",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        http()
          .delete(`/events/${id}`)
          .then((response) => {
            Swal.fire({
              title: "Deleted!",
              text: "Event has been deleted.",
              icon: "success",
              confirmButtonColor: "#e30613",
            });
            // Fetch the updated list of events after deletion
            fetchEvents();
          })
          .catch((error) => {
            Swal.fire({
              title: "Error!",
              text: "Something went wrong.",
              icon: "error",
            });
          });
      }
    });
  }

  // Function to fetch events from an API
  const fetchEvents = async () => {
    try {
      const response = await http().get(`/events/${props.idx}?page=${page}`);
      setPermissions(response.data.data.permission);
      if (
        response.status === 200 &&
        response.data?.data?.events?.data?.length > 0
      ) {
        setItems(response.data.data.events.data);
        setTotalPages(response.data.data.events.last_page);
      } else {
        setLoadMsg("No Record Found.");
      }
    } catch (error) {
      console.error("Error fetching events:", error);
      setLoadMsg("Error fetching events");
    }
  };

  useEffect(() => {
    try {
      fetchEvents();
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  }, []);

  useEffect(() => {
    try {
      http()
        .get("/eventcategories/" + props.idx)
        .then((res) => {
          setEventCategories(res.data.data);
        });
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    pavilion_id: props.idx,
    timezone: "",
    category_id: "",
    description: "",
    location: "",
    organizer: "",
    capacity: "",
    frequency: "",
    zoom_link: "",
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
    guest_or_all: "",
    invite_all_members: "",
    event_type: 1,
    event_meeting_type: eMType,
    meeting_status: isFree,
    online_price: '0',
    offline_price: '0',
    status: 1,
    meeting_type: "",
    image: eventBanner,
    // is_featured:'0',
  });

  const handleChange = (e) => {
    try {
      // if(e.target.type==='checkbox' && e.target.name==='featuredOfferings'){

      //    setFormData({ ...formData, is_featured: e.target.checked });

      // }
      if (e.target.type === "date" && e.target.name === "start_date") {
        setStartDate(e.target.value);
        return;
      }
      if (e.target.type === "time" && e.target.name === "start_time") {
        setStartTime(e.target.value);
        return;
      }
      if (e.target.type === "date" && e.target.name === "end_date") {
        setEndDate(e.target.value);
        return;
      }
      if (e.target.type === "time" && e.target.name === "end_time") {
        setEndTime(e.target.value);
        return;
      }

      setFormData({ ...formData, [e.target.name]: e.target.value });
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };
  const handleDateTimeChange = (field, type, value) => {
    setFormData((prev) => {
      const [date, time] = prev[field] ? prev[field].split(" ") : ["", ""];

      // Update either the date or time part
      const updatedValue =
        type === "date"
          ? `${value} ${time || "00:00"}`
          : `${date || "1970-01-01"} ${value}`;

      return {
        ...prev,
        [field]: updatedValue.trim(),
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.stopPropagation();
      }
      setValidated(true);

      const submitData = new FormData();
      submitData.append("name", formData.name);
      submitData.append("pavilion_id", props.idx);
      submitData.append("timezone", formData.timezone);
      submitData.append("category_id", formData.category_id);
      submitData.append("description", formData.description);
      
      if(eMType==='0' || eMType==='2'){
        submitData.append("location", formData.location);
      }
      
      submitData.append("organizer", formData.organizer);
      submitData.append("capacity", formData.capacity);
      submitData.append("frequency", formData.frequency);
      
      if(eMType==='1' || eMType==='2'){
         submitData.append("zoom_link", formData.zoom_link);
      }
      
      submitData.append("start_date", startDate);
      submitData.append("start_time", startTime);
      submitData.append("end_date", endDate);
      submitData.append("end_time", endTime);
      submitData.append("guest_or_all", formData.guest_or_all);
      submitData.append("invite_all_members", formData.invite_all_members);
      submitData.append("event_type", isOpen);
      submitData.append("event_meeting_type", eMType);
      submitData.append("meeting_status", isFree);
      
      if(isFree==='1'){
        submitData.append("online_price", formData.online_price);
      }

      submitData.append("offline_price", formData.offline_price);
      submitData.append("status", formData.status);
      submitData.append("meeting_type", formData.meeting_type);
      submitData.append("image", formData.image);

      if (form.checkValidity() === true) {
        setProgress(true);
        if (editEvent) {
          submitData.append("id", eventId);
          const response = await http().post("/eventupdate", submitData);
          if (response.data) {
            Swal.fire({
              title: "Your Event updated successfully",
              icon: "success",
              allowOutsideClick: false,
              confirmButtonColor: "#e30613",
            });
          }
        } else {
          const response = await http().post("/events", submitData);
          if (response.data) {
            Swal.fire({
              title: "Your Event created successfully",
              icon: "success",
              allowOutsideClick: false,
              confirmButtonColor: "#e30613",
            });
          }
          fetchEvents();
        }
        setProgress(false);
        setShow(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmitUpdate = async (e) => {
    e.preventDefault();
    try {
      // console.log(formData);
      setProgress(true);
      const response = await http().post("/eventupdate", formData);
      if (response.data) {
        Swal.fire({
          title: "Your Event updated successfully",
          icon: "success",
          allowOutsideClick: false,
          confirmButtonColor: "#e30613",
        });
        setShow(false);
        setProgress(false);
        fetchEvents();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {

    if (eventToEdit) {
      
      setFormData({
        ...formData,
        ...eventToEdit,
      });

      // setFormData({ ...formData, zoom_link: eventToEdit.meeting_start_link});
      
      if (eventToEdit.online === 0) {
        setEMType("0");
      }
      if (eventToEdit.online === 1) {
        setEMType("1");
      }
      if (eventToEdit.online === 2) {
        setEMType("2");
      }
      if (eventToEdit.is_paid === 0) {
        setIsFree("0");
      }
      if (eventToEdit.is_paid === 1) {
        setIsFree("1");
      }
      if (eventToEdit.status === 0) {
        setIsOpen("0");
      }
      if (eventToEdit.status === 1) {
        setIsOpen("1");
      }
      if (eventToEdit.start_date) {
        // setFormData({ ...formData, start_date: new Date(eventToEdit.start_date).toLocaleDateString('en-CA') });
        // setFormData({ ...formData, start_time: new Date(eventToEdit.start_date).toLocaleTimeString('en-GB') });

        setStartDate(
          new Date(eventToEdit.start_date).toLocaleDateString("en-CA")
        );
        setStartTime(
          new Date(eventToEdit.start_date)
            .toLocaleTimeString("en-GB")
            .substring(0, 5)
        );
      }
      if (eventToEdit.end_date) {
        //  setFormData({ ...formData, end_date: new Date(eventToEdit.end_date).toLocaleDateString('en-CA') });
        //  setFormData({ ...formData, end_time: new Date(eventToEdit.end_date).toLocaleTimeString('en-GB') });

        setEndDate(new Date(eventToEdit.end_date).toLocaleDateString("en-CA"));
        setEndTime(
          new Date(eventToEdit.end_date)
            .toLocaleTimeString("en-GB")
            .substring(0, 5)
        );
      }
    }
  }, [eventToEdit]);
  // Event Edit Work
  function handleEdit(id) {
    setShow(true);
    // Geting Data for edit event
    http()
      .get(`/events/${id}/edit`)
      .then((response) => {
        setEditEvent(true);
        setEventId(id);
        // console.log(response.data.data.eventRecord);
        const eventRecord = response.data.data.eventRecord; // Declare eventRecord here
        //setEventToEdit(response.data.data.eventRecord); // Set the event data to the state
		setEventToEdit({
        ...eventRecord,
        zoom_link: eventRecord.meeting_start_link || "", // Ensure fallback to an empty string
      });
        setImagePress(
          localStorage.getItem("bucketName") +
            "/images/events/" +
            response.data.data.eventRecord.image
        );
      });
  }

  // End Event Edit work

  useEffect(() => {
    setFormData({ ...formData, image: imagePress });
  }, [imagePress]);

  //Infinite Scroll
  //`${baseUrl}/events/${props.idx}?page=${page}`, {
  const fetchData = async (page) => {
    // console.log('pageno: ', page);
    // console.log('hasmore: ', hasMore);
    // console.log('total pages fetchData: ', totalPages)

    if (page <= totalPages) {
      http()
        .get(`/events/${props.idx}?page=${page}`)
        .then((response) => {
          setPage(response.data.data.events.current_page);
          setTotalPages(response.data.data.events.last_page);
          setItems([...items, ...response.data.data.events.data]);
          setHasMore(true);
          setLoadMsg(<PreLoader size={PLSIZE} />);
          setPermissions(response.data.data.permission);
        });
    } else {
      setHasMore(false);
      setLoadMsg("No More Records...");
    }
  };
  const [filterData, setFilterData] = useState({
    when: "",
    where: "",
    format: "",
    eventType: "",
    searchEvents: "",
  });

  const handleFilterChange = (e) => {
    setFilterData({ ...filterData, [e.target.name]: e.target.value });
  };

  const [isInterested, setIsInterested] = useState("primary");
  const handleInterestedClick = (id, interestedmsgdata) => {
    if (interestedmsgdata == "interested") {
      if (intesetedmsgcolor == "primary") {
        setIntesetedmsgcolor("secondary");
      } else {
        setIntesetedmsgcolor("primary");
      }
    } else {
      if (unintesetedmsgcolor == "primary") {
        setUnintesetedmsgcolor("secondary");
      } else {
        setUnintesetedmsgcolor("primary");
      }
    }

    /*var button = document.getElementById('event_'+id);
    if(button.style.textDecoration==='none'){
       button.style.textDecoration='line-through'
       button.style.backgroundColor='#fff'
       button.style.color='#e30613'
       button.style.border='1px solid #e30613'
    }else{
      button.style.textDecoration='none'
      button.style.backgroundColor='#e30613'
      button.style.color='#fff'
      button.style.border='1px solid #e30613'
    }*/
    http()
      .post("/webeventinterest/" + id, {
        event: "interested",
      })
      .then((response) => {
        Swal.fire({
          title: response.data.data.event,
          icon: "success",
          allowOutsideClick: false,
          confirmButtonColor: "#e30613",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        Swal.fire({
          title: "An error occurred. Please try again.",
          icon: "error",
        });
      });
  };
  const handleFilterSubmit = async (e) => {
    e.preventDefault();

    http()
      .post("/searchevents", {
        event_pavilion_id: props.idx,
        event_search_type: filterData.when,
        event_location: filterData.where,
        text_search_events: filterData.searchEvents,
        event_category_id: filterData.eventType,
      })
      .then((response) => {
        // alert(response.ok===true)

        setItems([...items, ...response.data.data.events.data]);
        console.log("search results: ", response.data); // Handle the response data
        setShowEvents(0);
        setPageTitle("Search Results");
      })
      .catch((error) => {
        console.error("Error:", error);
        Swal.fire({
          title: "An error occurred. Please try again.",
          icon: "error",
        });
      });
  };

  return (
    <>
      <ListGroup
        className="zoom overflow-hidden h-100 px-0 "
        style={{ borderRadius: "15px" }}
      >
        <ListGroup.Item
          variant="light"
          className="d-flex justify-content-between align-items-center  border-0 text-center py-0 "
        >
          <div>
            <IconButton
              className="p-1 m-1 btn btn-outline-mode"
              onClick={
                showEvents > 0
                  ? () => setShowEvents(0)
                  : (event) => props.handleClick("PVLN-MENU", props.idx)
              }
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="small">
              {showEvents === 0 && pageTitle}
              {showEvents === 1 && "Events Details"}
              {showEvents === 2 && "Search Events"}
            </Typography>
          </div>
          <IconButton
            className="p-1 m-1 btn btn-outline-mode"
            onClick={(event) => props.handleClick("PVLN-HOME", props.idx)}
          >
            <Home />
          </IconButton>
          <div>
            {permissions["create-events"] && (
              <IconButton
                className="p-1 m-1 btn btn-outline-mode"
                onClick={handleShow}
              >
                <PostAddIcon />
              </IconButton>
            )}
            {permissions["search-events"] && (
              <IconButton
                className="p-1 m-1 btn btn-outline-mode"
                onClick={() => setShowEvents(2)}
              >
                <FilterAltIcon />
              </IconButton>
            )}
          </div>
        </ListGroup.Item>
        {showEvents === 0 && (
          <ListGroup.Item className="d-flex align-items-start justify-content-center h-100  border-0 bg-light bg-opacity-75">
            <div
              className="container slim-scrollbar mh-70  py-0"
              style={{ overflow: "auto" }}
              ref={scrollContainerRef}
            >
              <InfiniteScroll
                pageStart={1}
                hasMore={hasMore}
                loadMore={fetchData}
                getScrollParent={() => scrollContainerRef.current}
                useWindow={false}
                initialLoad={false}
              >
                <Row xs={1} md={1} className="g-0 ">
                  {items.map((event, index) => (
                    <>
                      <Col key={index} className="py-2">
                        <Card
                          key={index}
                          className="mb-0 shadow-sm bg-light"
                          style={{ cursor: "pointer" }}
                        >
                          <Card.Img
                            style={{ pointerEvents: "auto" }}
                            variant="top"
                            src={`${localStorage.getItem(
                              "bucketName"
                            )}/images/events/${event.image}`}
                            onClick={() => handleEventClick(event.id)}
                          />
                          <Card.ImgOverlay style={{ pointerEvents: "none" }}>
                            <h4>
                              <span className="badge bg-primary">
                                {formatDate(event.start_date)}
                              </span>
                            </h4>
                          </Card.ImgOverlay>
                          <Card.Body
                            className="p-2 d-flex flex-column align-items-start justify-content-between "
                            style={{ minHeight: "75px" }}
                          >
                            <Card.Title
                              onClick={() => handleEventClick(event.id)}
                              className="pt-0 my-0 d-flex align-items-center justify-content-between w-100"
                              style={{ lineHeight: "0.7" }}
                            >
                              <Typography
                                key={index}
                                variant="large"
                                className="d-flex align-items-center text-dark small"
                              >
                                <BadgeAvatar
                                  isLoggedIn={false}
                                  src={
                                    localStorage.getItem("bucketName") +
                                    "/" +
                                    event.user_image
                                  }
                                  name={event.username}
                                  size="50"
                                />
                                <div className="ms-2">
                                  <strong>{event.name}</strong> <br></br>
                                  <small className="text-muted">
                                    Category: {event.category_name}
                                  </small>
                                </div>
                              </Typography>
                              {(permissions["edit-events"] ||
                                permissions["delete-events"]) && (
                                <CRUDDropdown
                                  title={<MoreVertIcon fontSize="small" />}
                                  handleDropdown={handleDropdown}
                                  editTitle={
                                    permissions["edit-events"]
                                      ? "Edit Event"
                                      : null
                                  }
                                  editClick={
                                    permissions["edit-events"]
                                      ? () => handleEdit(event.id)
                                      : null
                                  }
                                  deleteTitle={
                                    permissions["delete-events"]
                                      ? "Delete Event"
                                      : null
                                  }
                                  deleteClick={
                                    permissions["delete-events"]
                                      ? () => handleDelete(event.id)
                                      : null
                                  }
                                />
                              )}
                            </Card.Title>
                            <Card.Text className="d-flex align-items-center my-2 text-dark">
                              <LocationOnOutlinedIcon /> {event.location}
                            </Card.Text>
                            {/* <Button variant="primary" size="sm">{btnText[idx]}</Button>  */}
                          </Card.Body>
                          <Card.Footer className="mx-0 px-2 py-1 d-flex justify-content-between align-items-center border-top-0">
                            <small>
                              You and {event.total_members - 1} others
                              are interested
                            </small>
                            {/*<Button key={index} id={'event_'+ event.id} style={{textDecoration: 'line-through',pointerEvents:'auto'}} onClick={()=>handleInterestedClick(event.id)} variant='outline-primary' size="sm" className="px-1 py-0">*/}
                            {/*<Button key={index} id={'event_'+ event.id} style={{textDecoration: 'line-through',pointerEvents:'auto'}} onClick={()=>handleInterestedClick(event.id)} variant='outline-primary' size="sm" className="px-1 py-0">*/}

                            {event.interested ? (
                              <Button
                                value={index}
                                key={index}
                                style={{ pointerEvents: "auto" }}
                                onClick={(e) =>
                                  handleInterestedClick(event.id, intesetedmsg)
                                }
                                variant={intesetedmsgcolor}
                                size="sm"
                                className="px-1 py-0"
                              >
                                <StarIcon
                                  className="mb-1"
                                  sx={{ width: "15px", height: "15px" }}
                                />
                                Interested
                              </Button>
                            ) : (
                              <Button
                                value={index}
                                key={index}
                                style={{ pointerEvents: "auto" }}
                                onClick={(e) =>
                                  handleInterestedClick(
                                    event.id,
                                    unintesetedmsg
                                  )
                                }
                                variant={unintesetedmsgcolor}
                                size="sm"
                                className="px-1 py-0"
                              >
                                <StarIcon
                                  className="mb-1"
                                  sx={{ width: "15px", height: "15px" }}
                                />
                                Interested
                              </Button>
                            )}
                          </Card.Footer>
                        </Card>
                      </Col>
                    </>
                  ))}
                </Row>
              </InfiniteScroll>
              <div className="loader my-2 text-center" key={0}>
                {loadMsg}
              </div>
            </div>
          </ListGroup.Item>
        )}
        {showEvents === 1 && (
          <PVLNEventDetails
            pIndex={props.idx}
            rowIndex={rowIndex}
            handleClick={handleClick}
          />
        )}
        {showEvents === 2 && (
          <ListGroup.Item className="mx-0 px-0 d-flex align-items-start justify-content-center h-100 border-0 bg-light bg-opacity-75">
            <Container className="text-center">
              <Form className="mx-0 px-0" onSubmit={handleFilterSubmit}>
                <Form.Select
                  size="sm"
                  id="when"
                  name="when"
                  className="my-1 py-1"
                  value={filterData.when}
                  onChange={handleFilterChange}
                >
                  <option value="" selected disabled>
                    When
                  </option>
                  <option value="previous">Previous</option>
                  <option value="week">This Week</option>
                  <option value="month">This Month</option>
                  <option value="upcoming">Upcoming</option>
                </Form.Select>
                <Form.Select
                  name="where"
                  size="sm"
                  className="my-1 py-1"
                  value={filterData.where}
                  onChange={handleFilterChange}
                >
                  <option value="" selected disabled>
                    Where
                  </option>
                  <option value="0">Onsite</option>
                  <option value="1">Online</option>
                  <option value="2">Both</option>
                </Form.Select>
                <Form.Select
                  name="format"
                  size="sm"
                  className="my-1 py-1"
                  value={filterData.format}
                  onChange={handleFilterChange}
                >
                  <option value="" selected disabled>
                    Format
                  </option>
                  <option value="0">Meeting</option>
                  <option value="1">Webinar</option>
                </Form.Select>
                <Form.Select
                  name="eventType"
                  size="sm"
                  className="my-1 py-1"
                  value={filterData.eventType}
                  onChange={handleFilterChange}
                >
                  <option value="" selected disabled>
                    Event Type
                  </option>
                  {eventCategories.map((cat) => (
                    <option key={cat.id} value={cat.id}>
                      {cat.name}
                    </option>
                  ))}
                  <option value="">View All</option>
                </Form.Select>
                <Form.Control
                  size="sm"
                  name="searchEvents"
                  type="text"
                  placeholder="Search Events"
                  className="my-1 py-1"
                  value={filterData.searchEvents}
                  onChange={handleFilterChange}
                />
                <Button size="sm" type="submit">
                  Search
                </Button>
              </Form>
            </Container>
          </ListGroup.Item>
        )}
      </ListGroup>

      <Modal
        show={show}
        enforceFocus={false}
        size="lg"
        onHide={handleParentClose}
        data-bs-theme={theme}
        centered
      >
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          id="eventForm"
        >
          <Modal.Header closeButton className="py-1">
            {/* <Modal.Title className="lead" >Create Event</Modal.Title> */}
            <Modal.Title className="lead">
              {editEvent ? "Edit Event" : "Create Event"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={4} className="d-flex flex-column justify-content-center">
                <div>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Box my={2}>
                      <label htmlFor="fileBanner1">
                        <CropperInput
                          accept="image/*"
                          data-value="EVENTCROP"
                          id="fileBanner1"
                          type="file"
                          onChange={onFileChange}
                        />
                        <Image
                          style={{
                            borderRadius: "10px",
                            cursor: "pointer",
                            width: "100%",
                            height: "100%",
                          }}
                          src={imagePress}
                        />
                      </label>
                    </Box>
                    <CropperPopup
                      open={open}
                      image={imagePress2}
                      handleClose={handleClose}
                      getCroppedFile={(image) => {
                        setImagePress(image);
                        handleClose();
                      }}
                      aspectRatio={16 / 10}
                    />
                  </Box>
                </div>
              </Col>
              <Col md={8}>
                <Row>
                  <Form.Group as={Col} md={6} className="floating-labe">
                    <Form.Select
                      required
                      size="sm"
                      id="category"
                      className={
                        formData.category_id && formData.category_id > 0
                          ? "is-valid my-1  py-1"
                          : "is-invalid my-1  py-1"
                      }
                      name="category_id"
                      value={formData.category_id && formData.category_id}
                      onChange={handleChange}
                    >
                      <option value="" selected disabled>
                        Event Category
                      </option>
                      {eventCategories.map((cat, index) => (
                        <option value={cat.id}>{cat.name}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <ToggleButtonGroup
                    as={Col}
                    md={6}
                    type="radio"
                    onChange={handleEventMeetingTypeClick}
                    // value={formData.event_meeting_type && formData.event_meeting_type}
                    className="d-flex align-items-center"
                    id='eventMeetingType'
                    name="eventMeetingType"
                    defaultValue={0}
                  >
                    <ToggleButton
                      id="tbg-onsite"
                      size="sm"
                      className={
                        eMType === '0'
                          ? "text-bg-secondary pb-0 pt-1"
                          : "bg-outline-light pb-0 pt-1"
                      }
                      value={0}
                    >
                      On Site
                    </ToggleButton>
                    <ToggleButton
                      id="tbg-online"
                      size="sm"
                      className={
                        eMType === '1'
                          ? "text-bg-secondary pb-0 pt-1"
                          : "bg-outline-light  pb-0 pt-1"
                      }
                      value={1}
                    >
                      Online
                    </ToggleButton>
                    <ToggleButton
                      id="tbg-both"
                      variant="primary"
                      size="sm"
                      className={
                        eMType === '2'
                          ? "text-bg-secondary  pb-0 pt-1"
                          : "bg-outline-light  pb-0 pt-1"
                      }
                      value={2}
                    >
                      Both
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Row>
                {/* <Form.Control type="text" size="sm" name="event-name" placeholder="Event Name" className="my-2  py-1" name="name" value={formData.name} onChange={handleChange} /> */}
                <Form.Control
                  name="name"
                  required
                  size="sm"
                  type="text"
                  placeholder="Name"
                  className={
                    formData.name && Object.values(formData.name).length > 0
                      ? "is-valid"
                      : "is-invalid"
                  }
                  value={formData.name && formData.name}
                  onChange={handleChange}
                />
                {(eMType === "0" || eMType === "2") && (
                  <Form.Control
                    type="text"
                    required
                    className={`${
                      formData.location && Object.keys(formData.location).length > 0
                        ? "is-valid"
                        : "is-invalid"
                    } my-1 py-1`}
                    size="sm"
                    placeholder="Location"
                    name="location"
                    value={formData.location && formData.location}
                    onChange={handleChange}
                  />
                )}
                {(eMType === "1" || eMType === "2") && (
                  <Form.Control
                    type="text"
                    required
                    size="sm"
                    name="zoom_link"
                    placeholder="Registration Link"
                    className={`${
                      formData.zoom_link &&
                      Object.keys(formData.zoom_link).length > 0
                        ? "is-valid"
                        : "is-invalid"
                    } my-2 py-1`}
                    value={(formData.zoom_link && formData.zoom_link)}
                    onChange={handleChange}
                  />
                )}
                <Form.Group className="d-flex">
                  <Form.Select
                    required
                    size="sm"
                    id="frequency"
                    name="frequency"
                    className={
                      formData.frequency &&
                      Object.values(formData.frequency).length > 0
                        ? "is-valid my-1 me-2  py-1"
                        : "is-invalid my-1 me-2  py-1"
                    }
                    value={formData.frequency && formData.frequency}
                    onChange={handleChange}
                  >
                    <option value="" selected disabled>
                      Frequency
                    </option>
                    <option value="Once">Occurs Once</option>
                    <option value="Every Week">Every Week</option>
                    <option value="Every Month">Every Month</option>
                  </Form.Select>
                  <Form.Select
                    required
                    size="sm"
                    id="timezone"
                    name="timezone"
                    className={`${
                      formData.timezone &&
                      Object.values(formData.timezone).length > 0
                        ? "is-valid"
                        : "is-invalid"
                    } my-1 py-1`}
                    value={formData.timezone && formData.timezone}
                    onChange={handleChange}
                  >
                    <option value="" selected disabled>
                      Time Zone
                    </option>
                    <option value="Europe/London">(GMT+1:00) London</option>
                    <option value="Asia/Riyadh">(GMT+3:00) Riyadh</option>
                    <option value="Asia/Dubai">(GMT+4:00) Dubai</option>
                    <option value="Asia/Calcutta">(GMT+5:30) India</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="d-flex">
                  {/* <Form.Control className={`${formData.start_date && Object.values(formData.start_date).length>0 ? 'is-valid' : 'is-invalid'} my-1 me-2 w-50 py-1`} onFocus={(e) => { e.target.type = 'date' }} onBlur={(e) => { e.target.type = 'text' }} type="text" size="sm" name="start_date" placeholder="Start Date" value={formData.start_date && new Date(formData.start_date).toLocaleDateString('en-CA') } onChange={handleChange} />
                  <Form.Control className={`${formData.start_date && Object.values(formData.start_date.split(' ')[1].substring(0, 5)).length>0 ? 'is-valid' : 'is-invalid'} my-1 w-50 py-1`} onFocus={(e) => { e.target.type = 'time' }} onBlur={(e) => { e.target.type = 'text' }} type="text" size="sm" name="start_time" placeholder="Start TIme"  value={Object.values(formData.start_date).length>10 && formData.start_date.split(' ')[1].substring(0, 5)} onChange={handleChange} /> */}
                  <Form.Control
                    required
                    className={`${
                      startDate && startDate.length > 0
                        ? "is-valid"
                        : "is-invalid"
                    } my-1 me-2 w-50 py-1`}
                    onFocus={(e) => {
                      e.target.type = "date";
                    }}
                    onBlur={(e) => {
                      e.target.type = "text";
                    }}
                    type="text"
                    size="sm"
                    name="start_date"
                    placeholder="Start Date"
                    value={startDate && startDate}
                    onChange={handleChange}
                  />
                  <Form.Control
                    className={`${
                      startTime && startTime.length > 0
                        ? "is-valid"
                        : "is-invalid"
                    } my-1 w-50 py-1`}
                    onFocus={(e) => {
                      e.target.type = "time";
                    }}
                    onBlur={(e) => {
                      e.target.type = "text";
                    }}
                    type="text"
                    required
                    size="sm"
                    name="start_time"
                    placeholder="Start Time"
                    value={startTime && startTime}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="d-flex">
                  {/* <Form.Control className={`${formData.end_date && Object.values(formData.end_date).length>0 ? 'is-valid' : 'is-invalid'} my-1 me-2 w-50 py-1`} onFocus={(e) => { e.target.type = 'date' }} onBlur={(e) => { e.target.type = 'text' }} type="text" size="sm" name="end_date" placeholder="End Date" value={formData.end_date && new Date(formData.end_date).toLocaleDateString('en-CA') } onChange={handleChange} />
                <Form.Control className={`${formData.end_date && Object.values(formData.end_date).length>0 ? 'is-valid' : 'is-invalid'} my-1 w-50 py-1`} onFocus={(e) => { e.target.type = 'time' }} onBlur={(e) => { e.target.type = 'text' }} type="text" size="sm" name="end_time" placeholder="End TIme"  value={formData.end_date && formData.end_date.split(' ')[1].substring(0, 5)} onChange={handleChange} /> */}
                  <Form.Control
                    className={`${
                      endDate && endDate.length > 0 ? "is-valid" : "is-invalid"
                    } my-1 me-2 w-50 py-1`}
                    onFocus={(e) => {
                      e.target.type = "date";
                    }}
                    onBlur={(e) => {
                      e.target.type = "text";
                    }}
                    type="text"
                    required
                    size="sm"
                    name="end_date"
                    placeholder="End Date"
                    value={endDate && endDate}
                    onChange={handleChange}
                  />
                  <Form.Control
                    className={`${
                      endTime && endTime.length > 0 ? "is-valid" : "is-invalid"
                    } my-1 w-50 py-1`}
                    onFocus={(e) => {
                      e.target.type = "time";
                    }}
                    onBlur={(e) => {
                      e.target.type = "text";
                    }}
                    type="text"
                    size="sm"
                    required
                    name="end_time"
                    placeholder="End Time"
                    value={endTime && endTime}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Control
                  type="text"
                  size="sm"
                  required
                  name="organizer"
                  placeholder="Organizer"
                  className={`${
                    formData.organizer &&
                    Object.values(formData.organizer).length > 0
                      ? "is-valid"
                      : "is-invalid"
                  } my-2  py-1`}
                  value={formData.organizer && formData.organizer}
                  onChange={handleChange}
                />
                <Form.Group className="pt-1">
                  {/* <Form.Label>Purpose Description</Form.Label> */}
                  <CKEditor
                    editor={ClassicEditor}
                    data={formData.description && formData.description}
                    onReady={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                          "min-height",
                          "50px",
                          editor.editing.view.document.getRoot()
                        );
                      });

                      // You can store the "editor" and use when it is needed.
                      console.log("Further Description", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setFormData({ ...formData, description: data });
                      console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                      console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log("Focus.", editor);
                    }}
                  />
                </Form.Group>
                {/* <Form.Control type="text" size="sm" name="organiser" placeholder="Organiser" className="my-2  py-1" /> */}

                <Row className="mt-2">
                  <ToggleButtonGroup
                    onChange={handleChangeMS}
                    as={Col}
                    defaultValue={0}
                    md={6}
                    type="radio"
                    className="d-flex align-items-center"
                    name="meeting_status"
                  >
                    Meeting Status &nbsp;
                    <ToggleButton
                      id="tbg-free"
                      className={
                        isFree === "0"
                          ? "text-bg-secondary pb-0 pt-1"
                          : "bg-outline-light pb-0 pt-1"
                      }
                      size="sm"
                      value={0}
                    >
                      Free{" "}
                    </ToggleButton>
                    <ToggleButton
                      aria-controls="meetingPrice"
                      aria-expanded={isFree}
                      id="tbg-paid"
                      className={
                        isFree === "1"
                          ? "text-bg-secondary pb-0 pt-1"
                          : "bg-outline-light pb-0 pt-1"
                      }
                      size="sm"
                      value={1}
                    >
                      Paid
                    </ToggleButton>
                  </ToggleButtonGroup>

                  <ToggleButtonGroup
                    onChange={handleChangeOC}
                    defaultValue={1}
                    as={Col}
                    md={6}
                    type="radio"
                    className="d-flex align-items-center"
                    name="event_type"
                  >
                    Event Type &nbsp;{" "}
                    <ToggleButton
                      id="tbg-open"
                      className={
                        isOpen === "1"
                          ? "text-bg-secondary pb-0 pt-1"
                          : "bg-outline-light pb-0 pt-1"
                      }
                      size="sm"
                      value={1}
                    >
                      Open{" "}
                    </ToggleButton>
                    <ToggleButton
                      id="tbg-close"
                      className={
                        isOpen === "0"
                          ? "text-bg-secondary pb-0 pt-1"
                          : "bg-outline-light pb-0 pt-1"
                      }
                      size="sm"
                      value={0}
                    >
                      Close
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Row>
                {isFree==='1' && <Collapse in={isFree === '1'}>
                  <Form.Control
                    type="number"
                    id="meetingPrice"
                    size="sm"
                    className={`${
                      formData.online_price &&
                      formData.online_price > 0
                        ? "is-valid"
                        : "is-invalid"
                    } my-2 py-1`}
                    name="online_price"
                    placeholder="£ Online price for this meeting"
                    value={formData.online_price && formData.online_price}
                    onChange={handleChange}
                  />
                </Collapse>}
                <Form.Control
                  type="number"
                  required
                  size="sm"
                  name="capacity"
                  className={`${
                    formData.capacity &&
                    formData.capacity > 0
                      ? "is-valid"
                      : "is-invalid"
                  } my-2 py-1`}
                  placeholder="Capacity"
                  onkeypress="return /[0-9]/i.test(event.key)"
                  value={formData.capacity && formData.capacity}
                  onChange={handleChange}
                />

                <ToggleButtonGroup
                  type="radio"
                  className="d-flex align-items-center"
                  name="guest_or_all"
                  defaultValue={1}
                >
                  Invite &nbsp;
                  <ToggleButton
                    onChange={() => setIsGuests(false)}
                    id="tbg-inviteall"
                    variant="outline-secondary"
                    size="sm"
                    value={1}
                  >
                    Invite all members of this Pavilion
                  </ToggleButton>
                  {/* <ToggleButton onChange={() => setIsGuests(true)} id="tbg-guests" variant="outline-secondary" size="sm" value={2}>
                    Guests List
                  </ToggleButton> */}
                </ToggleButtonGroup>
                {/* <Collapse in={isGuests}>
                  <Select size="sm" isMulti autosize={true} className="basic-multi-select mt-2 w-100" placeholder="Ecosystem" classNamePrefix="select" options={ECOSYSTEM_OPTIONS} />
                </Collapse> */}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="py-1">
            <Row className="d-flex align-items-center">
              <Col md={9}>
                <Form.Check className="px-0 mb-0">
                  <Form.Check.Label for="featuredOfferings">
                    <Form.Check.Input
                      id="featuredOfferings"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          is_featured: e.target.checked ? "1" : "0",
                        })
                      }
                      value={formData.is_featured && formData.is_featured}
                      size="sm"
                      className="me-2"
                      name="featuredOfferings"
                      type="checkbox"
                    />
                    Marked as Featured Offerings
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check inline className="px-0">
                  <Form.Check.Label for="agree">
                    <Form.Check.Input
                      id="agree"
                      required
                      className="me-2"
                      size="sm"
                      name="agree"
                      type="checkbox"
                    />
                    By clicking Create Event, you agree to our Community
                    Guidelines | Privacy Policy | Terms and Conditions.
                  </Form.Check.Label>
                </Form.Check>
                {/* <Form.Check inline className='px-0 d-inline-flex'
               required 
               label="By clicking Create Event, you agree to our Community Guidelines | Privacy Policy | Terms and Conditions."
        /> */}
              </Col>
              <Col md={3} className="text-end">
                <Button
                  size="sm"
                  className="me-2"
                  variant="secondary"
                  onClick={handleParentClose}
                >
                  Cancel
                </Button>

                {eventToEdit ? (
                  <Button size="sm" type="submit" variant="primary">
                    {progress && (
                      <Spinner size="sm" className="me-2" animation="border" />
                    )}{" "}
                    Update
                  </Button>
                ) : (
                  <Button size="sm" type="submit" variant="primary">
                    {progress && (
                      <Spinner size="sm" className="me-2" animation="border" />
                    )}{" "}
                    Add
                  </Button>
                )}
              </Col>
            </Row>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
export default PVLNEvents;
