import React, { useState, useEffect } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { Card, Row, Col, Container, Image } from 'react-bootstrap';
import Tooltip from '@mui/material/Tooltip';
import Form from 'react-bootstrap/Form';
import { AccountBalanceOutlined } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import { Link, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BadgeAvatar from '../../../BadgeAvatar';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import ScheduleIcon from '@mui/icons-material/Schedule';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import DiversityIcon from '@mui/icons-material/Diversity2Outlined';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import http from '../../../../http';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import TelegramIcon from '@mui/icons-material/Telegram';
import Share from '@mui/icons-material/ReplyOutlined';
import Swal from 'sweetalert2'


function PVLNEventDetails(props, { handleClick }) {
  const [eventDetail, setEventDetail] = useState([]);
  const [members, setMembers] = useState([]);
  const [interestedMembers, setInterestedMembers] = useState([]);
  const [countDays, setCountDays] = useState(0);
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const [createdAt, setCreatedAtDate] = useState(0);
  
  const [eventId, setEventId] = useState(props.rowIndex);

  const handleImageError = (e) => {
    e.target.src = localStorage.getItem('bucketName')+'/images/noimage.webp';
  };

  const location = useLocation();
  const splitURL = location.pathname.toString().split("/");
  useEffect(() => {
      if (splitURL[1] === 'event') {
          if (splitURL[2] ) {
            setEventId(splitURL[2])
          } 
      }
      
  }, []);

  
  useEffect(() => {

    http().post('/events/' + eventId + '/details').then((res) => {
     
      setEventDetail(res.data.data.events[0]);
      setMembers(res.data.data.members);
      setInterestedMembers(res.data.data.intersted_memers);

      const startDate = new Date(res.data.data.events[0].start_date);
      const endDate = new Date(res.data.data.events[0].end_date);
      const createdAt = new Date(res.data.data.events[0].created_at);
      const currentDate = new Date();
      const millisecondsPerDay = 1000 * 60 * 60 * 24; // Number of milliseconds in a day

      const differenceInMilliseconds = startDate - currentDate;
      const differenceInDays = Math.ceil(differenceInMilliseconds / millisecondsPerDay);
      setCountDays(differenceInDays);
      

      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
      setStartDate(startDate.toLocaleString('en-US', options));
      setEndDate(endDate.toLocaleString('en-US', options));
      setEndDate(endDate.toLocaleString('en-US', options));
      setCreatedAtDate(createdAt.toLocaleString('en-US', options));

    });
  }, []);

  const socialPopUp = (dataurl) => {
    const width = 550;
    const height = 420;
    const left = (window.innerWidth / 2) - (width / 2);
    const top = (window.innerHeight / 2) - (height / 2);
    window.open(
      dataurl,
      'twitter-share-dialog',
      `width=${width},height=${height},left=${left},top=${top}`
    );
  };
  const handleCopyUrl = (dataurl) => {
    navigator.clipboard.writeText( dataurl).then(() => {
      Swal.fire({
        title: "Text copied to clipboard!",
        text : dataurl,
        icon: "success",
        allowOutsideClick: false,
        confirmButtonColor: "#e30613",
      });
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  };

  return (
    <ListGroup.Item className="d-flex align-items-start justify-content-center h-100  border-0 bg-light bg-opacity-75">
      <div className="container slim-scrollbar mh-70  py-0">
        <ListGroup className="overflow-hidden h-100 px-0 " style={{ borderRadius: "10px" }}>
          <ListGroup.Item active className='d-flex justify-content-start align-items-center  border-0 text-center py-1 '>
            <Row className="w-100 g-2 d-flex justify-content-between align-items-center ">
              <Col md={2}>
                <BadgeAvatar src={localStorage.getItem('bucketName')+ '/' + eventDetail.user_image} name={eventDetail.username} size='40' isLoggedIn={false} />
              </Col>
              <Col md={10} className=' text-start'>
                <h6 className='mb-0'>{eventDetail.username}</h6>
                {/* <small>{createdAt}</small> */}
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item className='d-flex justify-content-start align-items-center  border-0 text-center py-1 '>
            <Image fluid src={localStorage.getItem('bucketName')+'/' + eventDetail.image_eventdetails} onError={handleImageError} />
          </ListGroup.Item>
          <ListGroup.Item className='d-flex justify-content-between align-items-center  border-0 text-center py-1 '>
            <p className="d-flex-mi mb-0 text-primary">{startDate}</p>
            
              {/* <a href={`${process.env.PUBLIC_URL}/event/${eventDetail.id}`} target='_blank' className="btn btn-link d-flex-mi mt-0 p-0 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              <span><ShareRoundedIcon fontSize='small' /> Share</span></a> */}
                      <Dropdown as={Button} drop='up' variant="outline-light" className="p-0 m-0 btn-sm">
              <Dropdown.Toggle id="dropdown-basic" variant=""  className="w-100 small d-flex justify-content-center align-items-center p-0">
                <Share className='flipx' fontSize='small' /> Share 
              </Dropdown.Toggle>

              <Dropdown.Menu >
                <Dropdown.Item className='small'  onClick={()=>socialPopUp('https://telegram.me/share/url?url='+window.location.origin+'/event/'+eventDetail.id)} ><TelegramIcon fontSize='small' /> Telegram</Dropdown.Item>
                <Dropdown.Item className='small' onClick={()=>socialPopUp('https://www.facebook.com/sharer.php?u='+window.location.origin+'/event/'+eventDetail.id)} ><FacebookIcon fontSize='small'/> Facebook</Dropdown.Item>
                <Dropdown.Item className='small' onClick={()=>socialPopUp('https://twitter.com/share?url='+window.location.origin+'/event/'+eventDetail.id)} ><TwitterIcon fontSize='small'/> Twitter</Dropdown.Item>
                <Dropdown.Item className='small' onClick={()=>socialPopUp('https://www.linkedin.com/sharing/share-offsite/?url='+window.location.origin+'/event/'+eventDetail.id)} ><LinkedInIcon fontSize='small'/> LinkedIn</Dropdown.Item>
                <Dropdown.Item className='small' onClick={()=>handleCopyUrl(window.location.origin+'/event/'+eventDetail.id)} ><ContentPasteIcon fontSize='small'/> Copy to Clipboard</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            
            
          </ListGroup.Item>
          <ListGroup.Item className='d-flex justify-content-start align-items-center  border-0 text-center py-1 '>
            <h6 className="fw-bold">{eventDetail.name}</h6>
          </ListGroup.Item>
          <ListGroup.Item className='d-flex justify-content-start  border-0  py-1 '>
            <div className="tab-pane fade active show" id="about" role="tabpanel">
              <ul className="list-group list-group-light mx-0">
                <li className="list-group-item d-flex justify-content-between align-items-center mb-0">
                  <div className="d-flex align-items-center">
                    <ScheduleIcon />
                    <div className="ms-3">
                      <p className="mb-0 small fw-bold"> {startDate} - {endDate}</p>
                      <small className="text-muted mb-0">Starts within {countDays} days</small>
                    </div>
                  </div>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center mb-0">
                  <div className="d-flex align-items-center">
                    <LocationIcon />
                    <div className="ms-3">
                      <p className=" mb-0 small">Location</p>
                      <small className="text-muted mb-0 fw-bold">{eventDetail.location}</small>
                    </div>
                  </div>
                  <span className="badge rounded-pill badge-success bg-dark"><a href={`https://maps.google.com/?q=${eventDetail.location}`} target='_blank'><span className="badge rounded-pill badge-success bg-dark">Show Map</span></a></span>
                </li>
                {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <DiversityIcon />
                    <div className="ms-3">
                      <p className="mb-0 small">Created for {eventDetail.pavilion_name} at Big Innovation Centre</p>
                    </div>
                  </div>
                </li> */}
                {eventDetail.organizer && (
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <DiversityIcon />
                      <div className="ms-3">
                        <p className="mb-0 small">Organizer</p>
                        <small className="text-muted mb-0 fw-bold">{eventDetail.organizer}</small>
                      </div>
                    </div>
                    <span className="badge rounded-pill badge-success bg-dark">
                      <a href={eventDetail.meeting_start_link} target="_blank" rel="noopener noreferrer">
                        <span className="badge rounded-pill badge-success bg-dark">Link to event</span>
                      </a>
                    </span>
                  </li>
                )}
              </ul>
            </div>
             
          </ListGroup.Item>
          <ListGroup.Item className='d-flex justify-content-start  border-0  py-1 '>
            <div className="container p-0">
              <div className="border-bottom p-2 sidebar-footer text-light bg-primary rounded-top">
                <h5 className="mb-0 ">{interestedMembers} Interested</h5>
              </div>
              <div className="p-2 w-100" >
                <AvatarGroup>
                  {members.map((user, index) => (
                    <Tooltip title={user.name}
                    slotProps={{popper: {modifiers: [{ name: 'offset', options: {offset: [0, -14],},},],},}}
                  >
                    <Avatar sx={{"&:hover": {  bgcolor: "#e30613", borderColor:"#e30613", },}} as={Link} to={`/${user.profile_link}`} alt={user.name} src={localStorage.getItem('bucketName')+"/" + user.image} />
                    </Tooltip>
                    
                  ))}
                </AvatarGroup>
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item className='d-flex justify-content-start  border-0  py-1 '>
            <div className="container p-0">
              <div className="container p-0">
                <div className="border-bottom p-2 sidebar-footer text-light bg-primary rounded-top">
                  <h5 className="mb-0 ">Details</h5>
                </div>
                <div className='p-2' dangerouslySetInnerHTML={{ __html: eventDetail.description }}></div>
              </div>
            </div>
          </ListGroup.Item>
        </ListGroup>
      </div>
    </ListGroup.Item>
  );
}

export default PVLNEventDetails;
